<template>
  <div :class="['wit-menuMobile', { hidden: !showNavbar }]">
    <div class="wit-menuMobile-container">
      <router-link :to="`/`">
        <img
          class="wit-menuMobile__home"
          :src="publicPath + 'images/svg/logo-wit.svg'"
        />
      </router-link>
      <img
        @click="menuOpen = !menuOpen"
        class="wit-menuMobile__menu"
        :src="publicPath + 'images/svg/icon-hamburger.svg'"
      />
    </div>

    <!-- menu lista soluzioni -->
    <transition name="fade">
      <div class="wit-menuMobile-open" v-if="menuOpen">
        <div
          :class="[
            'wit-menuMobile-open__item',
            { selected: $route.params.service === element.url },
          ]"
          v-for="(element, elIndex) in content.chaptersNav"
          :key="elIndex"
        >
          <router-link :to="`/${element.url}`">
            {{ element.title }}
          </router-link>
        </div>
        <router-link :to="`/contatti`" class="wit-menuMobile-open__item">Contatti</router-link>
      </div>
    </transition>
  </div>
</template>
<script>
import json from "../assets/content/content.json";
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      menuOpen: false,
      showNavbar: true,
      lastScrollPosition: 0,
    };
  },
  computed: {
    content() {
      return json.home;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return;
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.wit-menuMobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
  background: $color-yellow;
  padding: 20px;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s ease;
  &.hidden {
    transform: translate3d(0, -100%, 0);
  }
  &-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__home {
    width: 80px;
  }
  &-open {
    width: 100vw;
    position: fixed;
    top: 90px;
    left: 0;
    z-index: 100;
    background: $color-grey-1-menu;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 161px 123px rgba(0, 0, 0, 0.02),
      0px 67.2619px 51.3865px rgba(0, 0, 0, 0.0143771),
      0px 35.9615px 27.4737px rgba(0, 0, 0, 0.0119221),
      0px 20.1597px 15.4015px rgba(0, 0, 0, 0.01),
      0px 10.7067px 8.17963px rgba(0, 0, 0, 0.00807786),
      0px 4.45528px 3.40373px rgba(0, 0, 0, 0.00562291);
    &__item {
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include text-style-menu-2;
      border-top: solid 1px $color-grey-1;
    }
  }
}
</style>
