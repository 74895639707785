<template>
  <div :class="['wit-footer', $mq]">
    <div class="wit-footer__contacts">
      <div class="container-xl">
        <div class="wit-footer__contacts-title col-md-6">
          {{ content.contacts.title }}
        </div>
        <div class="wit-footer__contacts-paragraph col-md-6">
          {{ content.contacts.paragraph }}
        </div>
        <div class="wit-footer__contacts-link col-md-6">
          <a href="mailto:email@witdesign.com">{{content.contacts.link}}</a>
        </div>
      </div>
    </div>
    <div class="wit-footer__share">
      <div class="container-xl">
        <div
          class="wit-footer__share-info col-md-12"
          v-html="content.share.info"
        ></div>
        <div class="wit-footer__share-social col-md-12">
          <a href="https://vimeo.com/user3493768" target="_blank"> 
          <img
            class="wit-footer__share-social__icon"
            :src="publicPath + 'images/svg/icon-social-vimeo.svg'"
          />
          </a>
          <a href="https://www.instagram.com/witdesignstudio/?hl=en" target="_blank">
          <img
            class="wit-footer__share-social__icon"
            :src="publicPath + 'images/svg/icon-social-instagram.svg'"
          />
          </a>
          <a href="https://www.facebook.com/WitDesignStudio/" target="_blank">
          <img
            class="wit-footer__share-social__icon"
            :src="publicPath + 'images/svg/icon-social-facebook.svg'"
          />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["content"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
.wit-footer {
  width: 100vw;
  &__contacts {
    background: $color-grey-bg;
    padding-top: $s-margin-L;
    padding-bottom: $s-margin-L;
    &-title {
      @include text-style-h-3;
      margin-bottom: $s-margin-XS;
    }
    &-paragraph {
      @include text-style-body-2;
      margin-bottom: $s-margin-S;
    }
    &-link {
      @include text-style-body-2;
      position: relative;
      width: fit-content;
      cursor: pointer;
      a:hover {
        text-decoration: none;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 15px;
        height: 3px;
        width: calc(100% - 30px);
        background: $color-yellow;
        transition: all 0.3s ease;
      }
      &:hover::after {
        background: $color-black-02;
      }
    }
  }
  &__share {
    background: $color-black-02;
    color: $color-white;
    padding-top: $s-margin-L;
    padding-bottom: $s-margin-XL;
    &-social {
      margin-top: $s-margin-S;
      &__icon {
        margin-right: $s-margin-S;
        cursor: pointer;
      }
    }
  }
  &.mobile {
    .wit-footer__contacts {
      &-title {
        @include text-style-h1-mobile;
        margin-bottom: $s-margin-XXS;
      }
      &-paragraph,
      &-link {
        @include text-style-body-M-mobile;
        margin-bottom: $s-margin-XXS;
      }
    }
  }
}
</style>
