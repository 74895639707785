<template>
  <div :class="['wit-moduleTitleParagraph', $mq]">
    <div class="container-xl">
      <div
        class="wit-moduleTitleParagraph-title col-md-6"
        v-html="content.title"
      ></div>
      <div
        class="wit-moduleTitleParagraph-paragraph col-md-8"
        v-html="content.paragraph"
      ></div>
      <div class="wit-moduleTitleParagraph-link col-md-6">
        <a :href="publicPath + 'pdf/advart_witdesign.pdf'" target="_blank">{{
          content.link
        }}</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["content"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>
<style lang="scss" scoped>
.wit-moduleTitleParagraph {
  width: 100vw;
  padding-top: $s-margin-S;
  padding-bottom: $s-margin-L;
  &-title {
    @include text-style-h-2;
    margin-bottom: $s-margin-XS;
  }
  &-paragraph {
    @include text-style-body-2;
    margin-bottom: $s-margin-S;
  }
  &-link {
    @include text-style-body-2;
    position: relative;
    width: fit-content;
    cursor: pointer;

    a:hover {
      text-decoration: none;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 15px;
      height: 3px;
      width: calc(100% - 30px);
      background: $color-yellow;
      transition: all 0.3s ease;
    }
    &:hover::after {
      background: $color-black-02;
    }
  }
  &.mobile {
    .wit-moduleTitleParagraph-title {
      @include text-style-h1-mobile;
    }
    .wit-moduleTitleParagraph-paragraph {
      @include text-style-body-M-mobile;
    }
  }
}
</style>
