<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view :key="$route.fullPath"/>
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "fade-in" : "fade-out";
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
  opacity: 100%;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overflow-x: hidden;
}
@media (min-width: 1100px) {
  .container-xl {
    max-width: 1100px !important;
  }
}
mark {
  padding: 0 0.2em !important;
  background-color: rgba(255, 205, 28, 1) !important;
}
a {
  cursor: pointer;
  color: rgba(10, 10, 10, 1) !important;
}
a.paragraph--link--underline {
  text-decoration: underline !important;
}
</style>
