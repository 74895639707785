<template>
  <div :class="['wit-moduleSliderMulti', content.background, $mq]">
    <div class="wit-moduleSliderMulti-container container-xl">
      <div class="wit-moduleSliderMulti-container-left col-md-6">
        <div class="wit-moduleSliderMulti__title">{{ content.title }}</div>
        <div class="wit-moduleSliderMulti__paragraph" v-html="content.paragraph ">
        </div>
        <div class="wit-moduleSliderMulti__link">
          <a :href="content.url" target="_blank">{{ content.link }}</a>
        </div>
      </div>
      <div class="wit-moduleSliderMulti-container-right col-md-6">
        <img
          v-if="content.coverImage"
          class="wit-moduleSliderMulti__image"
          :src="publicPath + 'images/gallery/' + content.coverImage + '.jpg'"
        />
      </div>
    </div>
    <flickity ref="flickity" :options="flickityOptions" v-if="content.slotList">
      <div
        class="carousel-cell"
        v-for="(slot, slotIndex) in content.slotList"
        :key="slotIndex"
      >
        <div
          class="slot-image"
          :style="{
            backgroundImage: `url(${publicPath}images/gallery/${slot.image}.jpg)`,
          }"
        ></div>
        <div class="wit-moduleSliderMulti__location">
          <img
            v-if="content.background === 'white'"
            class="location-icon"
            :src="
              publicPath +
                'images/svg/slot-location' +
                (content.free ? '-free' : '') +
                '.svg'
            "
          />{{ slot.location }}
        </div>
        <div class="wit-moduleSliderMulti__paragraph">
          {{ slot.paragraph }}
        </div>
      </div>
    </flickity>
  </div>
</template>
<script>
import Flickity from "vue-flickity";
export default {
  props: ["content"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: false,
        imagesLoaded: true,
        adaptiveHeight: false,
        groupCells: true,
      },
    };
  },
  components: {
    Flickity,
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
  },
};
</script>
<style lang="scss" scoped>
.wit-moduleSliderMulti {
  padding-top: $s-margin-L;
  &.white {
    background: $color-white;
  }
  &.black {
    background: $color-black-02;
    color: $color-white;
    .wit-moduleSliderMulti-container {
      display: flex;
      flex-direction: row;
      padding-bottom: $s-margin-L;
      width: 100vw;
      overflow: hidden;
      &-right {
        img {
          width: 100%;
        }
      }
    }
  }
  &__title {
    @include text-style-body-1;
    margin-bottom: $s-margin-XS;
  }
  &__paragraph {
    @include text-style-body-S-mobile;
  }
  &__link {
    @include text-style-body-3;
    text-decoration: underline;
    margin-bottom: $s-margin-M;
    padding-top: $s-margin-XS;
    a {
      color: white !important;
    }
  }
  .carousel-cell {
    height: fit-content;
    min-height: 650px !important;
    width: calc(100% / 2.5);
    padding-right: $s-margin-S;
    padding-bottom: $s-margin-S;
    .slot-image {
      width: 100%;
      height: 40vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 30%;
      margin-bottom: $s-margin-XS;
    }
  }
  .wit-moduleSliderMulti__location {
    display: flex;
    align-items: flex-start;
    @include text-style-body-2;
    margin-bottom: $s-margin-XS;
    .location-icon {
      width: 20px;
      margin-right: 10px;
    }
  }
  &.mobile {
    .wit-moduleSliderMulti {
      &__title {
        @include text-style-h1-mobile;
      }
      &__paragraph, &__link {
        @include text-style-body-M-mobile;
      }
    }
    .carousel-cell{
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
/* smaller, dark, rounded square */
.flickity-button {
  background: $color-white;
  transition: all 0.3s ease;
}
.flickity-button:hover {
  background: $color-yellow;
}

.flickity-prev-next-button {
  width: 40px;
  height: 40px;
  border-radius: 0px;
}
/* icon color */
.flickity-button-icon {
  fill: $color-black-01;
  left: 30%;
  top: 30%;
  width: 40%;
  height: 40%;
}
</style>
