<template>
  <div class="wit-advart">
    <!-------- MENU -------->
    <Menu v-if="$mq !== 'mobile'" />
    <MenuMobile v-if="$mq === 'mobile'" />


    <ModuleHeader :content="content[currentPage].name" />
    <template v-for="(component, i) in content[currentPage].components">
      <ModuleParagraph
        v-if="component.type === 'moduleParagraph'"
        :key="`component-${i}`"
        :content="component.content"
      />
      <ModuleIcons
        v-if="component.type === 'moduleIcons'"
        :key="`component-${i}`"
        :content="component.content"
      />
      <ModulePartners
        v-if="component.type === 'modulePartners'"
        :key="`component-${i}`"
        :content="component.content"
      />
      <ModuleSliderSingle
        v-if="component.type === 'moduleSliderSingle'"
        :key="`component-${i}`"
        :content="component.content"
      />
      <ModuleSliderMulti
        v-if="component.type === 'moduleSliderMulti' && $mq !== 'mobile'"
        :key="`component-${i}`"
        :content="component.content"
      />
      <ModuleSliderMultiMobile
        v-if="component.type === 'moduleSliderMulti' && $mq === 'mobile'"
        :key="`component-${i}`"
        :content="component.content"
      />
      <ModuleSliderGallery
        v-if="component.type === 'moduleSliderGallery'"
        :key="`component-${i}`"
        :content="component.content"
      />
      <ModuleVideo
        v-if="component.type === 'moduleVideo'"
        :key="`component-${i}`"
        :content="component.content"
      />
      <ModuleTitleParagraph
        v-if="component.type === 'moduleTitleParagraph'"
        :key="`component-${i}`"
        :content="component.content"
      />
    </template>
    <Footer :content="footerContent" />
  </div>
</template>

<script>
import json from "../assets/content/content.json";
import Menu from "../components/Menu.vue";
import MenuMobile from "../components/MenuMobile.vue";
import Footer from "../components/Footer.vue";
import ModuleHeader from "../components/modules/ModuleHeader.vue";
import ModuleParagraph from "../components/modules/ModuleParagraph.vue";
import ModuleIcons from "../components/modules/ModuleIcons.vue";
import ModulePartners from "../components/modules/ModulePartners.vue";
import ModuleSliderSingle from "../components/modules/ModuleSliderSingle.vue";
import ModuleSliderMulti from "../components/modules/ModuleSliderMulti.vue";
import ModuleSliderMultiMobile from "../components/modules/ModuleSliderMultiMobile.vue";
import ModuleSliderGallery from "../components/modules/ModuleSliderGallery.vue";
import ModuleVideo from "../components/modules/ModuleVideo.vue";
import ModuleTitleParagraph from "../components/modules/ModuleTitleParagraph.vue";
// import Footer from "../components/Footer.vue";
export default {
  name: "Services",
  components: {
    Menu,
    Footer,
    ModuleParagraph,
    ModuleIcons,
    ModulePartners,
    ModuleHeader,
    ModuleSliderSingle,
    ModuleSliderMulti,
    ModuleSliderGallery,
    MenuMobile,
    ModuleSliderMultiMobile,
    ModuleVideo,
    ModuleTitleParagraph
  },
  data() {
    return {};
  },
  computed: {
    content() {
      return json.pages;
    },
    footerContent() {
      return json.footer;
    },
    currentPage() {
      return this.$route.params.service;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.wit-advart {
  width: 100%;
/*   overflow-y: scroll;
 */}
</style>
