<template>
  <div :class="['wit-homepage', $mq]">
    <!-------- MENU -------->
    <Menu v-if="$mq !== 'mobile'" />
    <MenuMobile v-if="$mq === 'mobile'" />

    <div class="wit-homepage__chaptersNav">
      <Landing :content="content.landing" />
      <!-------- section home-bg-2 -------->
      <div
        class="wit-homepage__chaptersNav-background"
        :style="{
          backgroundImage: `url(${publicPath}images/bg_home_2.svg)`,
        }"
      >
        <ModuleParagraph :content="content.paragraph" />
        <div v-for="(card, cardIndex) in content.chaptersNav" :key="cardIndex">
          <CardLanding :content="card" />
        </div>
      </div>
    </div>
    <div class="wit-homepage__clients">
      <ModuleClients :content="content.clients" />
    </div>
    <Footer :content="footerContent" />
  </div>
</template>

<script>
import json from "../assets/content/content.json";
import Menu from "../components/Menu.vue";
import MenuMobile from "../components/MenuMobile.vue";
import Landing from "../components/Landing.vue";
import ModuleParagraph from "../components/modules/ModuleParagraph.vue";
import CardLanding from "../components/CardLanding.vue";
import ModuleClients from "../components/modules/ModuleClients.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Home",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  components: {
    Menu,
    Landing,
    ModuleParagraph,
    CardLanding,
    ModuleClients,
    Footer,
    MenuMobile,
  },
  computed: {
    content() {
      return json.home;
    },
    footerContent() {
      return json.footer;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.wit-homepage {
  width: 100%;
  /* overflow-y: auto; */

  &__chaptersNav {
    &-background {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top right;
    }
  }
  &__clients {
    background: $color-black-02;
    // height: 200vh;
    margin-top: calc(2 * -#{$s-margin-XL});
    padding-top: calc(3 * #{$s-margin-XL});
  }
  &.mobile {
    .wit-homepage__clients {
      margin-top: calc(2 * -#{$s-margin-L});
      padding-top: calc(2 * #{$s-margin-L});
    }
  }
}
</style>
