import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueMq from "vue-mq";
import VueLazyload from "vue-lazyload";
import vueVimeoPlayer from 'vue-vimeo-player'


Vue.config.productionTip = false;

Vue.use(VueMq, {
  breakpoints: { mobile: 500, tablet: 1200, other: Infinity },
});
Vue.use(VueLazyload);
Vue.use(vueVimeoPlayer)

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
