<template>
  <div :class="['wit-moduleIcons', $mq]">
    <div class="container-xl">
      <div class="row">
        <div class="wit-moduleIcons__title col-md-6">{{ content.title }}</div>
        <div class="wit-moduleIcons__paragraph col-md-6">
          {{ content.paragraph }}
        </div>
      </div>
      <div class="wit-moduleIcons__iconsList-container col-md-12">
        <div
          class="wit-moduleIcons__iconsList"
          v-for="(element, index) in content.iconSteps"
          :key="index"
        >
          <div :class="['wit-moduleIcons__iconsList-icon', element.icon]">
            <img :src="publicPath + 'images/svg/' + element.icon + '.svg'" />
          </div>
          <div class="wit-moduleIcons__iconsList-text">
            {{ element.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["content"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
.wit-moduleIcons {
  background: $color-black-02;
  color: white;
  width: 100vw;
  padding-top: $s-margin-L;
  padding-bottom: $s-margin-L;
  &__title {
    @include text-style-h-3;
  }
  &__paragraph {
    @include text-style-body-2;
  }
  &__iconsList-container {
    display: flex;
    justify-content: space-between;
    margin-top: $s-margin-L;
  }
  &__iconsList {
    width: calc(100% / 7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 1;
    max-width: 200px;
    @include text-style-body-3;
    text-align: center;
    &-icon {
      height: 100px;
      width: 100px;
      margin-bottom: $s-margin-XXS;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 80%;
      }
    }
  }
  &.mobile {
    .wit-moduleIcons {
      &__title {
        @include text-style-h1-mobile;
        margin-bottom: $s-margin-XXS;
      }
      &__paragraph {
        @include text-style-body-S-mobile;
      }
      &__iconsList-container {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: $s-margin-L;
        .wit-moduleIcons__iconsList {
          width: 100%;
          margin-bottom: $s-margin-XXS;
          &-icon {
            &.moduleIcons_arrow {
              display: none;
            }
          }
          &-text {
            width: 100%;
            text-align: center;
            @include text-style-body-S-mobile;
          }
        }
      }
    }
  }
}
</style>
