<template>
  <div
    :class="['wit-moduleHeader', $mq]"
    :style="{
      backgroundImage: `url(${publicPath}images/bg_sections.svg)`,
    }"
  >
    <div class="container-xl">
      <div class="wit-moduleHeader__title col-md-12">
        {{ content }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["content"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>
<style lang="scss" scoped>
.wit-moduleHeader {
  height: 280px;
  width: 100vw;
  display: flex;
  align-items: flex-end;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  &__title {
    @include text-style-h-1;
    padding-bottom: $s-margin-XS;
  }
  &.mobile {
    height: 200px;
    background-position: 80% 80%;
    .wit-moduleHeader__title {
      @include text-style-h-2;
    }
  }
}
</style>
