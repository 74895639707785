<template>
  <div :class="['wit-moduleSliderGallery', 'container-xl', $mq]">
    <!-------- FLICKITY SLIDER -------->
    <div class="wit-moduleSliderGallery__container" v-if="content.length !== 1">
      <flickity ref="flickity" :options="flickityOptions">
        <div
          class="carousel-cell"
          v-for="(image, imageIndex) in content"
          :key="imageIndex"
        >
          <img
            class="carousel-image"
            :data-flickity-lazyload="
              publicPath + 'images/gallery/' + image + '.jpg'
            "
          />
        </div>
      </flickity>
    </div>
    <!-------- SINGLE IMAGE -------->
    <div
      class="wit-moduleSliderGallery__container singleImage"
      v-if="content.length === 1"
    >
      <img
        class="carousel-image"
        :src="publicPath + 'images/gallery/' + content + '.jpg'"
      />
    </div>
    <!-------- FLICKITY BUTTONS -------->
    <div class="wit-moduleSliderGallery__buttons">
      <button @click="previous()">
        <img :src="publicPath + 'images/svg/icon-arrow-left.svg'" />
      </button>
      <button @click="next()">
        <img :src="publicPath + 'images/svg/icon-arrow-right.svg'" />
      </button>
    </div>
  </div>
</template>
<script>
import Flickity from "vue-flickity";
export default {
  props: ["content"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: true,
        wrapAround: true,
        lazyLoad: true,
        imagesLoaded: true,
      },
    };
  },
  components: {
    Flickity,
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
  },
};
</script>
<style lang="scss" scoped>
.wit-moduleSliderGallery {
  margin-bottom: $s-margin-L;
  &__container {
    padding: 10vh 0;
    &.singleImage {
      padding-top: 5vh;
      .carousel-image {
        max-width: 100%;
      }
    }
  }
  .carousel-cell {
    width: 100%;
    margin-right: 10px;
    img {
      width: 100%;
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: -50px;
    z-index: 1;
    button {
      height: 40px;
      width: 40px;
      background: $color-white;
      border: none;
      transition: all 0.3s ease;
      &:hover {
        background: $color-yellow;
      }
    }
  }
  &.mobile {
    .wit-moduleSliderGallery__container {
      &.singleImage {
        padding: 0 !important;
      }
    }
    .wit-moduleSliderGallery__buttons {
      margin-top: -53px;
      button {
        background: transparent;
      }
    }
  }
}
</style>
