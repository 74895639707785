<template>
  <div :class="['wit-modulePartners', $mq]">
    <div class="wit-modulePartners container-xl">
      <div class="wit-modulePartners__title col-md-6">{{ content.title }}</div>
      <!-------- DESKTOP lista loghi -------->
      <div
        v-if="$mq !== 'mobile'"
        class="wit-modulePartners__partnersList-container "
      >
        <div
          class="wit-modulePartners__partnersList"
          v-for="(element, index) in content.partnersList"
          :key="index"
        >
          <img
            class="wit-modulePartners__partnersList-icon"
            :src="publicPath + 'images/loghi/' + element.image + '.jpg'"
          />

          <div class="wit-modulePartners__partnersList-name">
            {{ element.name }}
          </div>
        </div>
      </div>
      <!-------- MOBILE lista loghi -------->
      <div
        v-show="$mq === 'mobile'"
        class="wit-modulePartners__partnersList-mobile "
      >
        <flickity ref="flickity" :options="flickityOptions">
          <div
            class="carousel-cell"
            v-for="(element, index) in content.partnersList"
            :key="index"
          >
            <div class="carousel-image">
              <img
                :src="publicPath + 'images/loghi/' + element.image + '.jpg'"
              />
              <div class="wit-modulePartners__partnersList-name">
                {{ element.name }}
              </div>
            </div>
          </div>
        </flickity>
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
export default {
  props: ["content"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        lazyLoad: true,
        imagesLoaded: true,
        autoPlay: true,
      },
    };
  },
  components: {
    Flickity,
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
  },
};
</script>

<style lang="scss" scoped>
.wit-modulePartners {
  width: 100vw;
  margin-top: $s-margin-L;
  margin-bottom: $s-margin-L;
  &__title {
    @include text-style-h-3;
  }
  &__partnersList-container {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
  }
  &__partnersList {
    width: calc(100% / 4);
    padding: $s-margin-XS 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    @include text-style-body-3;
    text-align: center;
    &-icon {
      height: 150px;
      width: 150px;
      background: grey; //cambiare
      margin-bottom: $s-margin-XS;
    }
  }
  &.mobile {
    .wit-modulePartners__title {
      @include text-style-h1-mobile;
    }
    .carousel-cell {
      width: 100%; /* full width */
      margin-right: 10px;
      min-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .carousel-image {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}
</style>
