<template>
  <div :class="['wit-moduleSliderMultiMobile', content.background]">
    <div class="wit-moduleSliderMultiMobile-container container-xl">
      <div class="wit-moduleSliderMultiMobile-container-left col-md-6">
        <div class="wit-moduleSliderMultiMobile__title">
          {{ content.title }}
        </div>
        <div class="wit-moduleSliderMultiMobile__paragraph" v-html="content.paragraph">
        </div>
      </div>
      <div class="wit-moduleSliderMultiMobile-container-right col-md-6">
        <img
          v-if="content.coverImage"
          class="wit-moduleSliderMultiMobile__image"
          :src="publicPath + 'images/gallery/' + content.coverImage + '.jpg'"
        />
      </div>
    </div>
    <flickity ref="flickity" :options="flickityOptions">
      <div
        class="carousel-cell"
        v-for="(slot, slotIndex) in content.slotList"
        :key="slotIndex"
      >
        <div
          class="slot-image"
          :style="{
            backgroundImage: `url(${publicPath}images/gallery/${slot.image}.jpg)`,
          }"
        ></div>
        <div class="wit-moduleSliderMultiMobile__location">
          <img
            v-if="content.background === 'white'"
            class="location-icon"
            :src="
              publicPath +
                'images/svg/slot-location' +
                (content.free ? '-free' : '') +
                '.svg'
            "
          />{{ slot.location }}
        </div>
        <div class="wit-moduleSliderMultiMobile__paragraph">
          {{ slot.paragraph }}
        </div>
      </div>
    </flickity>
    <div class="wit-moduleSliderMultiMobile__buttons" v-if="content.background !== 'black'">
      <button @click="previous()">
        <img :src="publicPath + 'images/svg/icon-arrow-left.svg'" />
      </button>
      <button @click="next()">
        <img :src="publicPath + 'images/svg/icon-arrow-right.svg'" />
      </button>
    </div>
  </div>
</template>
<script>
import Flickity from "vue-flickity";
export default {
  props: ["content"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: true,
        wrapAround: false,
        imagesLoaded: true,
        adaptiveHeight: false,
        groupCells: true,
      },
    };
  },
  components: {
    Flickity,
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
  },
};
</script>
<style lang="scss" scoped>
.wit-moduleSliderMultiMobile {
  width: 100vw;
  padding-top: $s-margin-L;
  margin-bottom: $s-margin-L;
  &.white {
    background: $color-white;
  }
  &.black {
    background: $color-black-02;
    color: $color-white;
    margin-bottom: 0;
    padding-bottom: $s-margin-S;
    .wit-moduleSliderMultiMobile-container {
      display: flex;
      flex-direction: column;
      &-right {
        img {
          width: 100%;
          margin-top: $s-margin-XS;
        }
      }
    }
  }
  &__title {
    @include text-style-h1-mobile;
    margin-bottom: $s-margin-XS;
  }
  &__paragraph {
    @include text-style-body-M-mobile;
  }
  &__link {
    @include text-style-body-M-mobile;
    text-decoration: underline;
    margin-bottom: $s-margin-M;
  }
  .carousel-cell {
    height: fit-content;
    width: 100%;
    padding: $s-margin-XS;
    .slot-image {
      width: 100%;
      height: 40vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 30%;
      margin-bottom: $s-margin-XS;
    }
  }
  .wit-moduleSliderMultiMobile__location {
    display: flex;
    @include text-style-h2-mobile;
    margin-bottom: $s-margin-XXS;
    .location-icon {
      width: 20px;
      margin-right: 10px;
    }
  }
  &__buttons {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    margin-top: 16px;
    button {
      background: none;
      border: none;
    }
  }
}
</style>
