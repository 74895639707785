import Vue from "vue";
import VueRouter from "vue-router";
import Homepage from "./views/Homepage.vue";
import ServicePage from "./views/ServicePage.vue";
import Contacts from "./views/Contacts.vue";

Vue.use(VueRouter);

export default new VueRouter({
  base: process.env.BASE_URL,
  // mode: 'history',
  routes: [
    {
      path: "/",
      name: "Home",
      component: Homepage,
    },
    {
      path: "/contatti",
      name: "contatti",
      component: Contacts,
    },
    {
      path: "/:service",
      component: ServicePage,
    },
  ],
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 300);
    });
  },
});
