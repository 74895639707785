<template>
  <div :class="['wit-module-video container-xl', $mq]">
    <iframe
      class="video"
      :src="content"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
    <p></p>
  </div>
</template>

<script>
export default {
  props: ["content"],
};
</script>
<style lang="scss" scoped>
.wit-module-video {
  width: 100vw;
  padding-top: 100px;
  padding-bottom: 50px;
  .video {
    width: 100%;
    height: auto;
    min-height: 70vh;
  }
  &.mobile {
    margin-top: 30px;
    padding-top:0;
    padding-bottom: 0;
    height: 30vh;
    .video {
      height: 100%;
      width: 100%;
      min-height: unset;
    }
  }
}
</style>
