<template>
  <div :class="['wit-menu', { hidden: !showNavbar }, {'transparent': lastScrollPosition < 60}]">
    <div class="wit-menu-container container-xl">
      <router-link :to="`/`">
        <img
          class="wit-menu_image"
          :src="publicPath + 'images/svg/logo-wit.svg'"
        />
      </router-link>
      <div class="wit-menu-right">
        <div
          class="wit-menu_item"
          @mouseenter="openMenu()"
          @mouseleave="openMenu()"
        >
          Le nostre soluzioni

          <!-- menu lista soluzioni -->
          <transition name="fade">
            <div
              class="wit-menu_listOpen"
              v-if="menuOpen"
              @mouseenter="menuOpen === true"
            >
              <div
                :class="[
                  'wit-menu_listOpen__item',
                  { selected: $route.params.service === element.url },
                ]"
                v-for="(element, elIndex) in content.chaptersNav"
                :key="elIndex"
                @click="openMenu()"
              >
                <router-link :to="`/${element.url}`">
                  {{ element.title }}
                </router-link>
              </div>
            </div>
          </transition>
        </div>
        <div
          :class="['wit-menu_item', { selected: $route.name === 'contatti' }]"
        >
          <router-link :to="`/contatti`">Contatti</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import json from "../assets/content/content.json";

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      menuOpen: false,
      showNavbar: true,
      lastScrollPosition: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  computed: {
    content() {
      return json.home;
    },
  },
  methods: {
    openMenu() {
      return (this.menuOpen = !this.menuOpen);
    },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return;
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.wit-menu {
  height: 100px;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5em 0;
  z-index: 2;
  background: rgba($color-white, 0.9);
  transform: translate3d(0, 0, 0);
  transition: all 0.3s ease;
  &.hidden {
    transform: translate3d(0, -100%, 0);
  }
  &.transparent {
    background: transparent;
  }
  &-container {
    height: 100%;
    width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-right {
    height: 100%;
    display: flex;
    position: relative;
  }
  &_image {
    width: 90px;
  }
  &_item {
    height: 100%;
    @include text-style-menu-1;
    margin-right: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &.selected a {
      // color: $color-yellow !important;
    }
  }
  &_listOpen {
    position: absolute;
    top: 50px;
    left: -65px;
    width: 250px;
    background: $color-grey-1-menu;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 161px 123px rgba(0, 0, 0, 0.02),
      0px 67.2619px 51.3865px rgba(0, 0, 0, 0.0143771),
      0px 35.9615px 27.4737px rgba(0, 0, 0, 0.0119221),
      0px 20.1597px 15.4015px rgba(0, 0, 0, 0.01),
      0px 10.7067px 8.17963px rgba(0, 0, 0, 0.00807786),
      0px 4.45528px 3.40373px rgba(0, 0, 0, 0.00562291);
    border: 1px solid $color-grey-1;
    &__item {
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include text-style-menu-2;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        background: $color-white;
      }
      &:not(:last-of-type) {
        border-bottom: solid 2px $color-grey-1;
      }
      &.selected a {
        // font-weight: 600 !important;
      }
    }
  }
}
</style>
