<template>
  <div :class="['wit-card-landing container-xl', content.type, $mq]">
    <div
      class="wit-card-landing__image"
      :style="{
        backgroundImage: `url(${publicPath}images/gallery/${content.image}.jpg)`,
      }"
    ></div>
    <router-link :to="`/${content.url}`">
      <div class="wit-card-landing__text">
        <div class="wit-card-landing__text-title">{{ content.title }}</div>
        <div class="wit-card-landing__text-paragraph">
          {{ content.paragraph }}
        </div>

        <img
          class="wit-card-landing__text-arrow"
          :src="publicPath + 'images/svg/link-arrow.svg'"
        />
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["content"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
.wit-card-landing {
  height: 430px;
  margin-bottom: $s-margin-L;
  background: $color-white;
  padding: 0 !important;
  display: flex;
  max-width: 90%;
  &.left {
    flex-direction: row-reverse;
  }
  &__image {
    width: 75%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__text {
    height: 100%;
    padding: $s-margin-M $s-margin-XS;
    position: relative;
    background: $color-white;
    color: $color-black-01;
    &-title {
      margin-bottom: $s-margin-XS;
      @include text-style-h-3;
    }
    &-paragraph {
      @include text-style-body-2;
    }
    &-arrow {
      position: absolute;
      bottom: $s-margin-XS;
      left: $s-margin-XS;
      transition: $s-transition;
      cursor: pointer;
    }
    &:hover {
      text-decoration: none;
      .wit-card-landing__text-arrow {
        left: calc(2 * #{$s-margin-XS});
      }
    }
  }
  a {
    text-decoration: none;
  }
  &.mobile {
    flex-direction: column;
    .wit-card-landing {
      &__image {
        width: 100%;
        height: 40%;
      }
      &__text {
        &-title {
          @include text-style-body-2;
        }
        &-paragraph {
          @include text-style-body-M-mobile;
          margin-bottom: $s-margin-XS;
        }
      }
    }
  }
}
</style>
