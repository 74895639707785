<template>
  <div :class="['wit-moduleSliderSingle', content.background, $mq]">
    <div class="wit-moduleSliderSingle-container container-xl">
      <div class="wit-moduleSliderSingle-container-left col-md-5">
        <div class="wit-moduleSliderSingle__title">
          {{ content.title }}
        </div>
        <div class="wit-moduleSliderSingle__location">
          <img
            :src="
              publicPath +
                'images/svg/slot-location' +
                (content.free ? '-free' : '') +
                '.svg'
            "
          />{{ content.location }}
        </div>
        <div
          class="wit-moduleSliderSingle__paragraph"
          v-html="content.paragraph"
        ></div>
      </div>
      <div class="wit-moduleSliderSingle-container-right col-md-6">
        <flickity ref="flickity" :options="flickityOptions">
          <div
            class="carousel-cell"
            v-for="(image, imageIndex) in content.gallery"
            :key="imageIndex"
          >
            <!-- <img
              class="carousel-image"
              :src="publicPath + 'images/gallery/' + image + '.jpg'"
            /> -->
            <div
              class="carousel-image"
              :style="{
                backgroundImage: `url(${publicPath}images/gallery/${image}.jpg)`,
              }"
            ></div>
          </div>
        </flickity>

        <div class="wit-moduleSliderSingle__buttons">
          <button @click="previous()">
            <img :src="publicPath + 'images/svg/icon-arrow-left.svg'" />
          </button>
          <button @click="next()">
            <img :src="publicPath + 'images/svg/icon-arrow-right.svg'" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Flickity from "vue-flickity";
export default {
  props: ["content"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: true,
        wrapAround: true,
        lazyLoad: 1,
        imagesLoaded: true,
        bgLazyLoad: true,
      },
    };
  },
  components: {
    Flickity,
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
  },
};
</script>
<style lang="scss" scoped>
.wit-moduleSliderSingle {
  width: 100vw;
  padding-top: $s-margin-L;
  padding-bottom: $s-margin-L;
  &.white {
    background: $color-white;
  }
  &.gray {
    background: $color-grey-bg;
  }
  &-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__title {
    @include text-style-h-3;
    margin-bottom: $s-margin-XS;
  }
  &__location {
    @include text-style-body-2;
    margin-bottom: $s-margin-XS;
    img {
      margin-right: 10px;
    }
  }
  &__paragraph {
    @include text-style-body-3;
  }
  &-container-right {
    button {
      height: 40px;
      width: 40px;
      background: $color-white;
      border: none;
      transition: all 0.3s ease;
      &:hover {
        background: $color-yellow;
      }
    }
    .wit-moduleSliderSingle__buttons {
      display: flex;
      justify-content: space-between;
      margin-top: $s-margin-XS;
      z-index: 1;
    }
  }
  .flickity-slider {
    width: 100%;
    height: auto;
  }
  .carousel-cell {
    width: 100%; /* full width */
    height: 400px;
    background: $color-black-01;
    /* center images in cells with flexbox */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .carousel-image {
    width: 100%;
    height: 100%;
    max-width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transform: scale(1.2);
    overflow: hidden;
  }
  &.mobile {
    .wit-moduleSliderSingle {
      .carousel-image {
        background-size: cover;
      }
      &-container {
        flex-direction: column;
      }
      &__title {
        @include text-style-h1-mobile;
      }
      &__location {
        @include text-style-body-M-mobile;
      }
      &__paragraph {
        @include text-style-body-M-mobile;
        margin-bottom: $s-margin-XS;
      }
      &__buttons {
        button {
          background: transparent;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.flickity-page-dots {
  bottom: -60px;
  z-index: 0;
  pointer-events: none;
}
/* dots are lines */
.flickity-page-dots .dot {
  height: 6px;
  width: 6px;
  border-radius: 0;
  background: $color-placeholder;
}
.flickity-page-dots .dot.is-selected {
  background: $color-yellow;
}
</style>
