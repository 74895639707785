<template>
  <div :class="['wit-clients', $mq]">
    <div class="wit-clients-container container-xl">
      <!-------- blocco testo -------->
      <div class="wit-clients-container-text">
        <div class="wit-clients-container-text__title col-md-6">
          {{ content.title }}
        </div>
        <div class="wit-clients-container-text__paragraph col-md-6">
          {{ content.paragraph }}
        </div>
      </div>

      <!-------- DESKTOP lista loghi -------->
      <div
        v-if="$mq !== 'mobile'"
        class="wit-clients-container-logoList container-xl"
      >
        <div
          class="wit-clients-container-logoList-logo"
          v-for="(element, index) in content.logoList"
          :key="index"
        >
          <img :src="publicPath + 'images/loghi/' + element + '.png'" />
        </div>
      </div>

      <!-------- MOBILE lista loghi -------->
      <div v-if="$mq === 'mobile'" class="wit-clients-container-logoList">
        <flickity ref="flickity" :options="flickityOptions">
          <div
            class="carousel-cell"
            v-for="(element, index) in content.logoList"
            :key="index"
          >
            <img
              class="carousel-image"
              :src="publicPath + 'images/loghi/' + element + '.png'"
            />
          </div>
        </flickity>
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
export default {
  props: ["content"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        lazyLoad: true,
        imagesLoaded: true,
        autoPlay: true
      },
    };
  },
  components: {
    Flickity,
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
  },
};
</script>

<style lang="scss" scoped>
.wit-clients {
  color: $color-white;
  &-container {
    &-text {
      display: flex;
      &__title {
        @include text-style-h-3;
      }
      &__paragraph {
        @include text-style-body-2;
      }
    }
    &-logoList {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-top: $s-margin-L;
      padding-bottom: calc(2 * #{$s-margin-L});
      &-logo {
        width: calc(100% / 3);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
          padding: 4vw;
        }
      }
    }
  }

  &.mobile {
    .wit-clients {
      &-container-text {
        flex-direction: column;
        &__title {
          @include text-style-h1-mobile;
          margin-bottom: $s-margin-XXS;
        }
        &__paragraph {
          @include text-style-body-M-mobile;
        }
      }
      &-container-logoList {
        display: inline;
        max-width: 100vw;
        padding: 20px;
        padding-bottom: $s-margin-XL;
      }
    }
  }
  .carousel-cell {
    width: 100%; /* full width */
    margin-right: 10px;
    min-height: 160px;
    display:flex;
    align-items: center;
    justify-content: center;
  }
  
  .carousel-image {
    width: 100%;
  }
}
.flickity-page-dots {
    bottom: 0px !important;
    z-index: 0;
    pointer-events: none;
}
</style>
