<template>
  <div class="wit-contatti">
    <!-------- MENU -------->
    <Menu v-if="$mq !== 'mobile'" />
    <MenuMobile v-if="$mq === 'mobile'" />

    <ModuleParagraph :content="content.paragraph" />
    <ModuleSliderGallery :content="content.image" />

    <Footer :content="footerContent" />
  </div>
</template>
<script>
import json from "../assets/content/content.json";
import Menu from "../components/Menu.vue";
import Footer from "../components/Footer.vue";
import ModuleParagraph from "../components/modules/ModuleParagraph.vue";
import ModuleSliderGallery from "../components/modules/ModuleSliderGallery.vue";
import MenuMobile from "../components/MenuMobile.vue";

export default {
  name: "Contatti",
  components: {
    Menu,
    Footer,
    ModuleParagraph,
    ModuleSliderGallery,
    MenuMobile
  },
  computed: {
    content() {
      return json.contatti;
    },
    footerContent() {
      return json.footer;
    },
  },
};
</script>
<style lang="scss" scoped>
.wit-contatti {
  padding-top: $s-margin-L;
}
</style>
