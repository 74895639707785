<template>
  <div :class="['wit-section-paragraph', $mq]">
    <div class="wit-section-paragraph-container container-xl">
      <div class="col-md-9" v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["content"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
.wit-section-paragraph {
  width: 100vw;
  padding-top: $s-margin-L;
  padding-bottom: $s-margin-L;
  @include text-style-body-1;
  &.mobile {
    @include text-style-body-2;
  }
}
</style>
