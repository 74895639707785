<template>
  <div
    :class="['wit-landing', $mq]"
    :style="{
      backgroundImage: `url(${publicPath}images/bg_home.svg)`,
    }"
  >
    <div class="wit-landing_text container-xl">
      <div class="col-md-7">{{ content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["content"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
.wit-landing {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  &_text {
    @include text-style-body-1;
    font-size: 36px;
    line-height: 38px;
    font-weight: 500;
  }
  &.mobile {
    background: $color-yellow;
    height: calc(100vh - 90px);
    background-image: none !important;
    .wit-landing_text {
      @include text-style-h-3;
    }
  }
}
</style>
